<template>
    <div class="container">
        <div class="row btm-margin">
            <div class="col-md-3"></div>
            <div class="col-md-6 tp-margin">
                <h1>Reset Password</h1>
            </div>
            <div class="col-md-3"></div>
        </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <b-form @submit.prevent="ResetPassword" >
                    <b-form-group id="input-group-Password" label-cols-sm="3" label="Password:" label-for="password">
                        <b-form-input id="password" type="password" v-model="$v.form.password.$model" :state="validateState('password')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.password.required">
                            Password is required
                        </b-form-invalid-feedback>
                        <b-form-text v-else-if="$v.form.password.$error" text-variant="info">
                            Your password should be <strong>strong</strong>. <br />
                            For that, your password should contain one digit and one special character.
                        </b-form-text>
                        <b-form-invalid-feedback v-if="$v.form.password.required && !$v.form.password.length">
                            Have length between 5-10 characters long
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="input-group-confirmedPassword" label-cols-sm="3" label="Confirm Password:" label-for="confirmedPassword" style="">
                        <b-form-input id="confirmedPassword" type="password" v-model="$v.form.confirmedPassword.$model" :state="validateState('confirmedPassword')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.confirmedPassword.required">
                            Password confirmation is required
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.confirmedPassword.sameAsPassword">
                            The confirmed password is not equal to the original password
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button type="submit" variant="dark" style="width:250px;" class="ml-5 w-75">Submit</b-button>


                </b-form>
                <b-alert class="mt-2" v-if="form.submitError" variant="warning" dismissible show>
                    Reset failed: {{ form.submitError }}
                </b-alert>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</template>
    

<script lang="js">
    import axios from 'axios';

    import {
        required,
        minLength,
        maxLength,
        sameAs
    } from "vuelidate/lib/validators";
    
    export default {
        name: "Reset",
        data() {
            return {
                form: {
                    password: "",
                    confirmedPassword: "",
                    submitError: undefined
                },
                errors: [],
                validated: false
            };
        },
        validations: {
            form: {
                password: {
                required,
                length: (p) => minLength(5)(p) && maxLength(10)(p)
            },
            confirmedPassword: {
                required,
                sameAsPassword: sameAs("password")
            },
            }
        },
        mounted() {
            // console.log("mounted");
            // console.log($v);
        },
        methods: {
            validateState(param) {
                const {
                    $dirty,
                    $error
                } = this.$v.form[param];
                return $dirty ? !$error : null;
            },
            async ResetPassword() {
                try {
                    await axios.post(this.$store.state.server_domain + "Reset",{
                        password: this.form.password,
                        token: this.$route.params.token
                    }, this.$store.state.config).then((res) => {
                        if (res.data.data) {
                            // this.$store.state.userId = res.data[0];
                            console.log(res.data);
                            console.log(res.data.data);
                            console.log(this.$route.params.token);
                            
                            //this.$root.store.login(this.form.username);
                            this.$router.push("/login");
                            this.$root.toast("Reset", `${res.data}`, "success");
                        } else {
                            this.$root.toast("Reset", `${res.data.error}`, "danger");
                        }
                    }).catch((err) => {
                        this.$root.toast("Reset", `${err.response.data.error}`, "danger");
                    });
                } catch (err) {
                    this.$root.toast("Reset", `${err.response.data.error}`, "danger");

                }
            },
        }
    };
</script>
    
<style>
.btm-margin {
    margin-bottom: 3%;
}

.tp-margin {
    margin-top: 3%;
}
</style>
    